import { canActivate, emailVerified, AuthPipe } from '@angular/fire/auth-guard';
import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

// const redirectToSignin = () => redirectUnauthorizedTo(['auth', 'signin'])
const redirectUnverifiedTo: (redirect: string|any[]) => AuthPipe = (redirect) => pipe(emailVerified, map(emailVerified => emailVerified || redirect));
const redirectToSignin = () => redirectUnverifiedTo(['auth', 'signin']);
export const redirectVerifiedTo: (redirect: string|any[]) => AuthPipe = (redirect) => pipe(emailVerified, map(emailVerified => emailVerified && redirect || true));
const redirectToDashboard = () => redirectVerifiedTo(['tracked-links']);

export const AppRoutes: Routes = [
    {
      path: '',
      redirectTo: 'tracked-links',
      pathMatch: 'full',
    },
    {
      path: '',
      component: AdminLayoutComponent,
      children: [
        {
          path: 'tracked-links',
          loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
          ... canActivate(redirectToSignin),
        },
        {
          path: 'untracked-qrcodes',
          loadChildren: () => import('./modules/untracked-qrcode-generator/untracked-qrcode-generator.module').then(m => m.UntrackedQrcodeGeneratorModule),
          ... canActivate(redirectToSignin),
        },
        {
          path: 'settings',
          loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
          ... canActivate(redirectToSignin),
        }
      ]
    },
    {
      path: '',
      component: AuthLayoutComponent,
      children: [
        {
          path: 'auth',
          loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
          ... canActivate(redirectToDashboard),
        }
      ]
    }
];
