import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import {MatNativeDateModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';

import { NgxsModule } from '@ngxs/store';

import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { NavbarModule} from './shared/components/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AppRoutes } from './app.routing';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { AppState } from './model/app-state';
import { ApiModule, Configuration, ConfigurationParameters }from '@cod/qrifi-service-angular-client';
import { TranslocoRootModule } from './transloco-root.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { BaseComponent } from './shared/components/base/base.component';
import { LoadingDialogComponent } from './shared/components/dialoges/loading-dialog/loading-dialog.component';
import { CropImageDialogComponent } from './shared/components/dialoges/crop-image-dialog/crop-image-dialog.component';
import { ConfirmationDialogComponent } from './shared/components/dialoges/confirmation-dialog/confirmation-dialog.component';
import { CorsInterceptor } from './shared/interceptors/cors.interceptor';
import { InformationOnlyDialogComponent } from './shared/components/dialoges/information-only-dialog/information-only-dialog.component';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler.service';

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatButtonModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule
  ],
  declarations: [
    BaseComponent,
    LoadingDialogComponent,
    CropImageDialogComponent,
    ConfirmationDialogComponent,
    InformationOnlyDialogComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslocoRootModule,
    FontAwesomeModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTooltipModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CorsInterceptor,
      multi: true
    } 
  ]
})
export class MaterialModule {}

export function apiConfigFactory (): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.apiServer
  }
  return new Configuration(params);
}

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.consoleHost,
    expiryDays: 30,
    name: environment.consoleHost,
    secure: environment.production
  },
  autoOpen: false
};

@NgModule({
    imports:      [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(AppRoutes,{
          useHash: true
        }),
        HttpClientModule,
        TranslocoRootModule,
        MaterialModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        ApiModule.forRoot(apiConfigFactory),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        NgxsModule.forRoot([AppState], {
          developmentMode: !environment.production
        }),
        NgcCookieConsentModule.forRoot(cookieConfig)
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent
    ],
    providers : [
      MatNativeDateModule,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: CorsInterceptor,
        multi: true
      },
      {
        provide: ErrorHandler,
        useClass: GlobalErrorHandlerService
      }
    ],
    bootstrap:    [ AppComponent ]
})
export class AppModule { }
