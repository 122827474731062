import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-information-only-dialog',
  templateUrl: './information-only-dialog.component.html',
  styleUrls: ['./information-only-dialog.component.css']
})
export class InformationOnlyDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InformationOnlyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {titleTranslationKey: string, contentTranslationKey: string}) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
