import { Injectable } from "@angular/core";
import { LinkResponse, SubsciptionSummary, SubscriptionInfoResponse } from "@cod/qrifi-service-angular-client";
import { Action, State, StateContext, StateToken } from "@ngxs/store";
import { LinkModel } from "./link-model";
import { LocalLinkConfig } from "./local-link-config";
import { StateActions } from "./state-actions";

export const APP_STATE_TOKEN = new StateToken<AppState>('appState');
export interface AppStateModel {
    availableLinks?: LinkResponse[]
    linkMap?: {[key: string]: LinkModel};
    subscriptions?: SubscriptionInfoResponse[];
    subscriptionSummary?: SubsciptionSummary;
    apiKey?: string;
    paymentActive?: boolean;

    selectedLinks: string[];
    localLinksConfig: {[key: string]: LocalLinkConfig};    
}

@State<AppStateModel>({
    name: APP_STATE_TOKEN,
    defaults: {
        linkMap: {},
        selectedLinks: [],
        localLinksConfig: {},
        paymentActive: true
    }

})
@Injectable()
export class AppState {

    @Action(StateActions.GetApiKey)
    public getApiKey(ctx: StateContext<AppStateModel>, action: StateActions.GetApiKey) {
        const state = ctx.getState();
        ctx.patchState({
            apiKey: action.payload.apiKey,
        });
    }

    @Action(StateActions.GetSubscriptions)
    public getSubscriptions(ctx: StateContext<AppStateModel>, action: StateActions.GetSubscriptions) {
        const state = ctx.getState();
        ctx.patchState({
            subscriptions: action.payload.subscriptions,
        });
    }

    @Action(StateActions.GetSubscriptionSummary)
    public getSubscriptionSummary(ctx: StateContext<AppStateModel>, action: StateActions.GetSubscriptionSummary) {
        const state = ctx.getState();
        ctx.patchState({
            subscriptionSummary: action.payload.subscriptionSummary,
        });
    }

    @Action(StateActions.UpdatePaymentActive)
    public updatePaymentActive(ctx: StateContext<AppStateModel>, action: StateActions.UpdatePaymentActive) {
        const state = ctx.getState();
        ctx.patchState({
            paymentActive: action.payload.paymentActive
        });
    }

    @Action(StateActions.UpdateSelectedLink)
    public updateSelectedLink(ctx: StateContext<AppStateModel>, action: StateActions.UpdateSelectedLink) {
        const state = ctx.getState();
        const sourceId = action.payload.sourceId;

        let selectedLinks = [... state.selectedLinks];

        if (action.payload.selected && !selectedLinks.includes(sourceId)) {
            selectedLinks.push(sourceId)
            ctx.patchState({
                selectedLinks: selectedLinks
            });
        } else {
            ctx.patchState({
                selectedLinks: [... selectedLinks.filter(linkId => linkId != sourceId)],
            });
        }
    }

    @Action(StateActions.UpdateSelectedLinkAndData)
    public updateSelectedLinkAndData(ctx: StateContext<AppStateModel>, action: StateActions.UpdateSelectedLinkAndData) {
        const state = ctx.getState();
        const sourceId = action.payload.sourceId;

        let selectedLinks = [... state.selectedLinks];
        let linkMap = {... state.linkMap};

        let linkModel: LinkModel = new LinkModel();
        linkModel.data = action.payload.linkStats;
        linkModel.summary = action.payload.linkStatSummary;
        linkMap[sourceId] = linkModel;

        if (action.payload.selected) {
            if (!selectedLinks.includes(sourceId)) {
                selectedLinks.push(sourceId)
            }
            ctx.patchState({
                selectedLinks: selectedLinks,
                linkMap: linkMap
            });
        } else {
            ctx.patchState({
                selectedLinks: [... selectedLinks.filter(linkId => linkId != sourceId)],
            });
        }
    }

    @Action(StateActions.UpdateAvailableLinks)
    public updateAvailableLinks(ctx: StateContext<AppStateModel>, action: StateActions.UpdateAvailableLinks) {
        const state = ctx.getState();

        if (action.payload && Array.isArray(action.payload)) {
            ctx.setState({
                ...state,
                availableLinks: action.payload,
            });
        }
    }

    @Action(StateActions.UpdateLink)
    public updateLink(ctx: StateContext<AppStateModel>, action: StateActions.UpdateLink) {
        const state = ctx.getState();

        let availableLinks = [... state.availableLinks];

        const index = availableLinks.findIndex(link => {
            return link.sourceId == action.payload.sourceId
        });

        if(index > -1) {
            availableLinks[index] = action.payload
            ctx.setState({
                ...state,
                availableLinks: availableLinks,
            });
        }
    }
}
