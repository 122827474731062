import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { TranslocoRootModule } from 'src/app/transloco-root.module';
@NgModule({
    imports: [ RouterModule, CommonModule, TranslocoRootModule ],
    declarations: [ NavbarComponent ],
    exports: [ NavbarComponent ]
})

export class NavbarModule {}
