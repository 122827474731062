
<div class="wrapper">
    <div class="sidebar" data-color="primary" data-background-color="white">
        <app-sidebar-cmp></app-sidebar-cmp>
    </div>
    <div class="main-panel">
        <app-navbar-cmp></app-navbar-cmp>
        <router-outlet></router-outlet>
        <div *ngIf="!isMap()">
            <app-footer-cmp></app-footer-cmp>
        </div>
    </div>
</div>
