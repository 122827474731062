<footer class="footer ">
  <div class="container-fluid">
    <nav class="pull-left">
      <ul>
        <li>
          <a href="javascript:void(0);" (click)="openDialog('footer.imprint.modal-title', 'footer.imprint.modal-content')">
            {{ 'footer.imprint.link-title' | transloco }}
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="openDialog('footer.terms-and-conditions.modal-title', 'footer.terms-and-conditions.modal-content')">
            {{ 'footer.terms-and-conditions.link-title' | transloco }}
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="openDialog('footer.privacy-policy.modal-title', 'footer.privacy-policy.modal-content')">
            {{ 'footer.privacy-policy.link-title' | transloco }}
          </a>
        </li>
      </ul>
    </nav>
    <div class="copyright pull-right">
      &copy;
      {{today | date: 'yyyy'}}, <a href="https://qrify.me" target="_blank"><img src="../../../assets/img/qrify.me-logo-writing.svg"/></a>
    </div>
  </div>
</footer>
