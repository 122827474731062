import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-layout',
  templateUrl: './auth-layout.component.html'
})
export class AuthLayoutComponent extends BaseComponent implements OnInit {
  mobile_menu_visible: any = 0;
  private _router: Subscription;

  constructor(private router: Router, private element: ElementRef) { super(); }
  ngOnInit(){
    const navbar: HTMLElement = this.element.nativeElement;
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).pipe(takeUntil(this._destroy$)).subscribe((event: NavigationEnd) => {
      const $layer = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
      }
    });
  }
}
