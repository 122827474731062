import { Injectable } from '@angular/core';
import { SubscriptionService } from './subscription.service';
import swal from 'sweetalert2';
import { ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { StateService } from './state.service';
import { ActivatedRoute, Router } from '@angular/router';

declare var TwoCoInlineCart: any
declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class TwoCheckoutService {

  cartOpened = new Subject<boolean>();
  paymentFinalized = new ReplaySubject<boolean>();

  cartOpenedEventRef: any;
  cartClosedEventRef: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private subscriptionService: SubscriptionService, private stateService: StateService, private translocoService: TranslocoService) { }

  initEventlisteners(): void {
    if (!this.cartOpenedEventRef) {
      var subject = this.cartOpened
      this.cartOpenedEventRef =
        TwoCoInlineCart.events.subscribe('cart:opened', function () {
          subject.next(true)
        });
    }

    if (!this.cartClosedEventRef) {
      var cartOpened = this.cartOpened
      this.cartClosedEventRef =
        TwoCoInlineCart.events.subscribe('cart:closed', function () {
          cartOpened.next(false)
        });
    }
  }

  openCheckout(purchaseItem: string, email: string, externalCustomerReference?: string, avangateCustomerReference?: string) {
    this.initEventlisteners();

    TwoCoInlineCart.cart.setCartLockedFlag(false);
    TwoCoInlineCart.products.removeAll();

    TwoCoInlineCart.products.add({
        code: purchaseItem,
        quantity: 1,
        // TODO OO use options to put options like linkcount => it will be possible to change count later without having a countchange for old subscriptions
//                          options: [{
//                                name: "somename"
//                              value: "somevalue"
//                          }],
//                          recurrence: MONTH. // only for dynamic products?
//                          duration: {},
//                          externalReference: 'test-item-external-ref'
    });

    TwoCoInlineCart.billing.setEmail(email)

    TwoCoInlineCart.cart.setTest(true)
    // TwoCoInlineCart.cart.setOrderExternalRef("") // todo oo use empty db entry id and when callback appears save data to this dataset
    // TwoCoInlineCart.cart.addCoupons(['CUPON']);
    TwoCoInlineCart.cart.setCurrency('EUR')

    if (avangateCustomerReference) {
        TwoCoInlineCart.cart.setCustomerReference(avangateCustomerReference)
    }

    let subscriptionInfoArray = this.subscriptionService.getUidEmailAndSubscriptionSummary();

    TwoCoInlineCart.cart.setReturnMethod({
      type: 'redirect',
      url: `${environment.purchaseCallbackUrl}?product=${purchaseItem}&checksum=${subscriptionInfoArray[2].availableLinksChecksum}&checksumUntracked=${subscriptionInfoArray[2].availableUntrackedQrCodesChecksum}`
    })
    TwoCoInlineCart.cart.setExternalCustomerReference(externalCustomerReference)
    TwoCoInlineCart.cart.setCartLockedFlag(true)

    // TwoCoInlineCart.cart.setSignature('<signature>');
    // TwoCoInlineCart.cart.setSource("youtube,affiliate,xxx");
    TwoCoInlineCart.cart.checkout()
  }

  openPopupNotEnoughLink(used?: number, available?: number, untracked = false) {
    let usedAvailable = ''
    if (used && available) {
      usedAvailable = this.translocoService.translate(
        untracked ? 'modals.outofuntracked.used-available' : 'modals.outoflinks.used-available', { used: used, available: available})
    }

    const popup = swal.fire({
      title: `${this.translocoService.translate(
        untracked ? 'modals.outofuntracked.title' : 'modals.outoflinks.title', { usedlinks: usedAvailable })}`,
      html: untracked
        ? `<p>
            ${this.translocoService.translate('modals.outofuntracked.instructions')}
          </p>
          <p>
            ${this.translocoService.translate('modals.outofuntracked.moreinfo', { homepage: environment.homepageUrl})}
          </p>
          
          <div class="mb-4">
            <button id="swalClose" class="btn btn-secondary" style="width: 80%;">
              ${this.translocoService.translate('modals.outofuntracked.button-back')}
            </button>
            <button id="swalUntracked" class="btn btn-primary" style="width: 80%;">
              ${this.translocoService.translate('modals.outofuntracked.button-untracked')}
            </button>
            <button id="swalSinglePurpose" class="btn btn-primary" style="width: 80%;">
              ${this.translocoService.translate('modals.outofuntracked.button-single-purpose')}
            </button>
          </div>`
        : `<p>
            ${this.translocoService.translate('modals.outoflinks.instructions')}
          </p>
          <p>
            ${this.translocoService.translate('modals.outoflinks.moreinfo', { homepage: environment.homepageUrl})}
          </p>
          
          <div class="mb-4">
            <button id="swalClose" class="btn btn-secondary" style="width: 80%;">
              ${this.translocoService.translate('modals.outoflinks.button-back')}
            </button>
            <button id="swalSinglePurpose" class="btn btn-primary" style="width: 80%;">
              ${this.translocoService.translate('modals.outoflinks.button-single-purpose')}
            </button>
            <button id="swalAdvanced" class="btn btn-primary" style="width: 80%;">
              ${this.translocoService.translate('modals.outoflinks.button-advanced')} 
            </button>
            <button id="swalEnterprise" class="btn btn-primary" style="width: 80%;">
              ${this.translocoService.translate('modals.outoflinks.button-enterprise')}
            </button>
          </div>
        `,
      icon: 'warning',
      buttonsStyling: false,
      cancelButtonText: 'back',
      confirmButtonText: 'purchase more',
      customClass:{
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary',
      },
      reverseButtons: true,
      showCancelButton: false,
      showConfirmButton: false,

    }).then((result) => {
      if (result.value) {
        let values = this.subscriptionService.getUidEmailAndSubscriptionSummary()
        this.openCheckout(values[1], values[0], values[2]?.avangateCustomerReference || '')
      }
    })

    $('#swalClose').on('click', () => {
      swal.close()
    });

    $('#swalUntracked').on('click', () => {
      let values = this.subscriptionService.getUidEmailAndSubscriptionSummary()
      this.openCheckout('qrifyme-untracked', values[1], values[0], values[2]?.avangateCustomerReference || '')
      swal.close()
    });
    $('#swalSinglePurpose').on('click', () => {
      let values = this.subscriptionService.getUidEmailAndSubscriptionSummary()
      this.openCheckout('qrifyme-single-purpose', values[1], values[0], values[2]?.avangateCustomerReference || '')
      swal.close()
    });
    $('#swalAdvanced').on('click', () => {
      let values = this.subscriptionService.getUidEmailAndSubscriptionSummary()
      this.openCheckout('qrifyme-advanced', values[1], values[0], values[2]?.avangateCustomerReference || '')
      swal.close()
    });
    $('#swalEnterprise').on('click', () => {
      let values = this.subscriptionService.getUidEmailAndSubscriptionSummary()
      this.openCheckout('qrifyme-enterprise', values[1], values[0], values[2]?.avangateCustomerReference || '')
      swal.close()
    });

    return popup;
  }

  startWaitingForSubscriptionChangeAfterRedirect(checksum: string, checksumUntracked: string) {
    swal.fire({
      background: '--gray-dark',
      title: this.translocoService.translate('toasts.payment-finalized.waiting-for-data'),
      toast: true,
      didOpen: () => {
        swal.showLoading();
      },
      position: 'bottom-right',
      showConfirmButton: false
    });
    this.checkForNewSubscriptions(checksum, checksumUntracked, 0, 1000);
  }

  async checkForNewSubscriptions(checksum: string, checksumUntracked: string, amountOfCalls: number, timeout: number) {
    const subsriptionSummary = await this.stateService.readSubscriptionSummary('hide-errors', 'check-for-subscriptions-after-purchase');
    if (subsriptionSummary && checksum !== subsriptionSummary?.availableLinksChecksum
        || checksumUntracked !== subsriptionSummary?.availableUntrackedQrCodesChecksum) {
      this.router.navigate(
        [], 
        {
          relativeTo: this.activatedRoute,
          queryParams: {
            'product': null,
            'checksum': null,
            'checksumUntracked': null,
          },
          queryParamsHandling: 'merge',
        }
      );
      swal.close();
      swal.fire({
        icon: 'success',
        background: '--gray-dark',
        title: this.translocoService.translate('toasts.payment-finalized.available-links-updated'),
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true
      });
    } else if (amountOfCalls > 10) {
      swal.close();
      swal.fire({
        icon: 'warning',
        background: '--gray-dark',
        title: this.translocoService.translate('toasts.payment-finalized.updating-took-too-long'),
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true
      });
    } else {
      setTimeout( () => this.checkForNewSubscriptions(checksum, checksumUntracked, amountOfCalls+1, timeout+1000), timeout);
    }
  }
}
