import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnDestroy {

  protected _destroy$ = new Subject();

  ngOnDestroy(): void {
    this._destroy$.next && this._destroy$.next();
    this._destroy$.complete && this._destroy$.complete();
  }
}
