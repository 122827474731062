import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { AuthService } from '../shared/services/auth.service';
import { UiStateService } from '../services/ui-state.service';
import { environment } from 'src/environments/environment';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/tracked-links',
        title: 'navigation.tracked-links',
        type: 'link',
        icontype: 'query_stats'
    },
    {
        path: '/untracked-qrcodes',
        title: 'navigation.untracked-qrcodes',
        type: 'link',
        icontype: 'qr_code_2'
    },
    {
        path: '/settings',
        title: 'navigation.settings',
        type: 'link',
        icontype: 'settings'
    },
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ps: any;

    constructor(private authService: AuthService, private router: Router, public uistate: UiStateService) {}

    get consoleUrl() {
        return environment.consoleUrl;
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
    }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    logout(): void {
        this.uistate.showLoadingDialog();
        this.authService.signout()
            .then(() => this.router.navigate(['auth', 'signin']))
            .finally(() => this.uistate.hideLoadingDialog());
    }
}
