import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { BaseComponent } from './shared/components/base/base.component';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { TranslocoService } from '@ngneat/transloco';
import { StateService } from './services/state.service';
import { TwoCheckoutService } from './services/two-checkout.service';
import swal from 'sweetalert2';


@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html'
})

export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  private _router: Subscription;
  private statusChangeSubscription!: Subscription;

  constructor(private router: Router, private translocoService: TranslocoService, private cookieConsentService: NgcCookieConsentService, private stateService: StateService, private twoCheckoutService: TwoCheckoutService) {
    super()
  }

  ngOnInit() {
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).pipe(takeUntil(this._destroy$)).subscribe((event: NavigationEnd) => {
      const body = document.getElementsByTagName('body')[0];
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      if (body.classList.contains('modal-open')) {
        body.classList.remove('modal-open');
        modalBackdrop.remove();
      }
    });

    this.statusChangeSubscription = this.cookieConsentService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        switch (event.status) {
          // TODO OO disable and enable Cookies for 2checkout.
          case 'deny':
            this.stateService.updatePaymentActive(false);
            swal.fire({
              title: `${this.translocoService.translate('modals.cookies.denied.title')}`,
              html: `
                ${this.translocoService.translate('modals.cookies.denied.description')}

                <div class="mb-4">
                  <button id="swalCloseCockieDeniedHint" class="btn btn-secondary" style="width: 80%;">
                    ${this.translocoService.translate('modals.cookies.denied.button-close')}
                  </button>
                </div>
              `,
              icon: 'warning',
              buttonsStyling: false,
              showCancelButton: false,
              showConfirmButton: false,
        
            });
            $('#swalCloseCockieDeniedHint').on('click', () => {
              swal.close()
            });
            break;
          case 'allow':
            this.stateService.updatePaymentActive(true);
        }
      });

      this.translocoService.events$.subscribe(translocoEvent => {
        switch (translocoEvent.type) {
          case 'langChanged':
          case 'translationLoadSuccess':
            this.cookieConsentService.init({
              ... this.cookieConsentService.getConfig(),
              autoOpen: true,
              content:{
                message: this.translocoService.translate('cookies.message'),
                allow: this.translocoService.translate('cookies.allow'),
                deny: this.translocoService.translate('cookies.deny'),
                link: this.translocoService.translate('cookies.link'),
                policy: this.translocoService.translate('cookies.policy'),
                href: 'https://www.2checkout.com/legal/cookie-policy/'
              },
              palette: {
                popup: {
                  background: '#333'
                },
                button: {
                  background: '#00bbf9',
                  text: '#fff'
                },
                highlight: {
                  background: '#fafafa',
                  text: '#333'
                }
              },
              theme: 'classic',
              type: 'opt-out',
              position: 'bottom-right',
            })

            this.stateService.updatePaymentActive(!this.cookieConsentService.hasAnswered() || this.cookieConsentService.hasConsented()); 

            // after successful payment --- 
            const product = this.getParameterByName('product')
            const checksum = this.getParameterByName('checksum')
            const checksumUntracked = this.getParameterByName('checksumUntracked')
      
            if (product) { // TODO OO add check for specific products
              this.twoCheckoutService.startWaitingForSubscriptionChangeAfterRedirect(checksum, checksumUntracked);
            }
            break;
        }
      }
    );

    /* intercept fetch requests (using fetch-intercept library)
    register({
      request: function (url, config) {
        console.log('fetch intercept');
        console.log(url);
        // Modify the url or config here
        config = config || {};
        const headers = config.headers || {};

        config.headers = {
          ...headers,
          'Access-Control-Allow-Origin': environment.consoleUrl
        };
        console.log(config.headers);
        return [url, config];
      }, 
      requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
      },

      response: function (response) {
          // Modify the reponse object
          return response;
      },

      responseError: function (error) {
          // Handle an fetch error
          return Promise.reject(error);
      } 
    });
    */
  }
  
  getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  ngOnDestroy(): void {
    this.statusChangeSubscription.unsubscribe();
  }
}
