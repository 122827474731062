export const environment = {
  firebase: {
    projectId: 'qrify-me',
    appId: '1:402575565684:web:711901630952ee610a4221',
    storageBucket: 'qrify-me.appspot.com',
    apiKey: 'AIzaSyBcSMJMBHegpg3AceHHunynG3doABGX86c',
    authDomain: 'qrify-me.firebaseapp.com',
    messagingSenderId: '402575565684',
  },
  production: true,
  apiServer: 'https://api.qrify.me',
  consoleHost: 'console.qrify.me',
  consoleUrl: 'https://console.qrify.me',
  purchaseCallbackUrl: 'https://console.qrify.me/#/tracked-links',
  homepageUrl: 'https://qrify.me/',
  maxUploadSizeInMb: 20
};
