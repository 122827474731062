<button type="button" class="close" aria-label="Close" (click)="closeDialog()">
    <i class="material-icons" style="font-size: 36px;">close</i>
</button>
<h2>{{ data.titleTranslationKey | transloco }}</h2>
<div class="container-fluid">
    <div class="row">
        <div [innerHTML]="data.contentTranslationKey | transloco"></div>
    </div>
</div>
<div class="text-center">
    <button type="button" class="btn btn-secondary" (click)="closeDialog()">{{ 'modals.buttons.close' | transloco }}</button>
</div>
        