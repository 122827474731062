import { Injectable, OnDestroy } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { SubsciptionSummary } from '@cod/qrifi-service-angular-client';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService implements OnDestroy {

  protected _destroy$ = new Subject();

  @Select('appState.subscriptionSummary')
  subscriptionSummary$: Observable<SubsciptionSummary>
  
  subscriptionSummary: SubsciptionSummary;

  constructor(private auth: Auth) {
    this.subscriptionSummary$.pipe(takeUntil(this._destroy$)).subscribe(info => this.subscriptionSummary = info);
  }

  ngOnDestroy(): void {
    this._destroy$.next && this._destroy$.next();
    this._destroy$.complete && this._destroy$.complete();
  }

  getUidEmailAndSubscriptionSummary(): [uid: string, email: string, subscriptionSummary: SubsciptionSummary] {
    return [
      this.auth.currentUser.uid,
      this.auth.currentUser.email,
      this.subscriptionSummary]
  }
}
