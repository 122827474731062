import { Injectable } from '@angular/core';
import swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor() { }

  showToast(icon: SweetAlertIcon, title: string, timer?: number | undefined, timerProgressBar: boolean = false) {
    return swal.fire({
      icon: icon,
      background: '--gray-dark',
      title: title,
      toast: true,
      position: 'center',
      showConfirmButton: false,
      timer: timer,
      timerProgressBar: timerProgressBar
    });
  }
}
