import { LinkResponse, Stat, StatSummary, SubsciptionSummary, SubscriptionInfoResponse } from "@cod/qrifi-service-angular-client";

export namespace StateActions {

  export class GetApiKey {
    static readonly type = "[GET apiKey] update state for apiKey";
    constructor(public payload: {
      apiKey: string
    }) {}
  }

  export class GetSubscriptions {
    static readonly type = "[GET subscriptions] update state list of subscriptions";
    constructor(public payload: {
      subscriptions: SubscriptionInfoResponse[]
    }) {}
  }

  export class GetSubscriptionSummary {
    static readonly type = "[GET subscription info] update state subscription info";
    constructor(public payload: {
      subscriptionSummary: SubsciptionSummary
    }) {}
  }

  export class UpdatePaymentActive {
    static readonly type = "[payment active] Update Payment Active";
    constructor(public payload: {
      paymentActive: boolean
    }) {}
  }

  export class UpdateSelectedLink {
    static readonly type = "[selectedLinks] Update Selected Link";
    constructor(public payload: {
      sourceId: string,
      selected: boolean
    }) {}
  }

  export class UpdateSelectedLinkAndData {
    static readonly type = "[selectedLinks and data] Update Selected Link and Data";
    constructor(public payload: {
      sourceId: string,
      selected: boolean,
      linkStats: Stat[],
      linkStatSummary: StatSummary
    }) {}
  }

  export class UpdateAvailableLinks {
    static readonly type = "[Links] Update Available Links";
    constructor(public payload: LinkResponse[]) {}
  }

  export class UpdateLink {
    static readonly type = "[Links] Update Link";
    constructor(public payload: LinkResponse) {}
  }
}
