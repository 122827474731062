import { Injectable } from "@angular/core";
import { formatDate } from '@angular/common';
import { Auth } from "@angular/fire/auth";
import { Select, Store } from "@ngxs/store";
import { LinksApiService, DeleteLinkRequest, Stat, StatSummary, SubscriptionApiService, SubsciptionSummary, SubscriptionInfoResponse, ApiKeyApiService, ApiKeyResponse, LinkResponse } from "@cod/qrifi-service-angular-client"
import { Observable } from "rxjs";
import { AppStateModel, AppState } from "../model/app-state";
import { StateActions } from "../model/state-actions";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { saveAs } from "file-saver";
import { ErrorHandlerService } from "./error-handler.service";

@Injectable({
    providedIn: 'root'
})
export class StateService {

  @Select(AppState)
  appState$: Observable<AppStateModel>;

  constructor(
    private auth: Auth,
    private store: Store,
    private linkService: LinksApiService,
    private subscriptionService: SubscriptionApiService,
    private apiKeyService: ApiKeyApiService,
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) { }

  updatePaymentActive(paymentActive: boolean): void  {
    this.store.dispatch(new StateActions.UpdatePaymentActive({paymentActive}));
  }

  async getApiKey(exceptionCallback?: Function): Promise<void> {
    let token = await this.auth.currentUser.getIdToken().catch(error => {
      this.errorHandler.showErrorMessage(['request.error.auth.general'], error);
      if (exceptionCallback) {
        exceptionCallback();
      }
    });
    if (!token)
      return;

    this.subscriptionService.configuration.accessToken = token
    let response: ApiKeyResponse | void = await this.apiKeyService.apiApikeysGet().toPromise().catch(error => {
      console.log(error)
      if (error?.status == 404) {
        return;
      }
      this.errorHandler.showErrorMessage(['request.error.general', 'request.error.apikey.load-failed'], error);
      if (exceptionCallback) {
        exceptionCallback();
      }
    });
    if (response) {
      this.store.dispatch(new StateActions.GetApiKey({ apiKey: response.apiKey }));
    }
  }

  async readSubscriptions(exceptionCallback?: Function): Promise<void> {
    let token = await this.auth.currentUser.getIdToken().catch(error => {
      this.errorHandler.showErrorMessage(['request.error.auth.general'], error);
      if (exceptionCallback) {
        exceptionCallback();
      }
    });
    if (!token)
      return;

    this.subscriptionService.configuration.accessToken = token
    let response: SubscriptionInfoResponse[] | void = await this.subscriptionService.apiSubscriptionsGet().toPromise().catch(error => {
      this.errorHandler.showErrorMessage(['request.error.auth.general', 'request.error.subscriptions.load-failed'], error);
      if (exceptionCallback) {
        exceptionCallback();
      }
    });
    if (response) {
      this.store.dispatch(new StateActions.GetSubscriptions({ subscriptions: response })).toPromise();
    }
  }

  async readSubscriptionSummary(
    baseErrorMessageId: 'request.error.auth.general' | 'request.error.subscription-summary.general' | 'request.error.deletion.general' | 'hide-errors',
    additionalMessageId?: 'deletion' | 'check-for-subscriptions-after-purchase' | 'summary-not-up-to-date' | 'untracked-not-usable' | undefined,
    exceptionCallback?: Function,
    existingToken?: string): Promise<SubsciptionSummary> {
    let token = existingToken ? existingToken : await this.auth.currentUser.getIdToken().catch(error => {
      this.handleSubscriptionSummaryError(error, 'request.error.auth.general', additionalMessageId, exceptionCallback);
      return undefined;
    });
    if (!token)
      return Promise.reject();

    this.subscriptionService.configuration.accessToken = token;
    return this.subscriptionService.apiSubscriptionsInfoGet().toPromise()
      .then(subscriptionSummary => {
        this.store.dispatch(new StateActions.GetSubscriptionSummary({ subscriptionSummary: subscriptionSummary })).toPromise();
        return Promise.resolve(subscriptionSummary);
      })
      .catch(error => {
        if (baseErrorMessageId !== 'hide-errors') {
          this.handleSubscriptionSummaryError(error, baseErrorMessageId, additionalMessageId, exceptionCallback);
        }
        return undefined;
      });
  }

  handleSubscriptionSummaryError(
    error: any,
    messageIdOperation: 'request.error.auth.general' | 'request.error.subscription-summary.general' | 'request.error.deletion.general',
    additionalMessageId?: 'deletion' | 'check-for-subscriptions-after-purchase' | 'summary-not-up-to-date' | 'untracked-not-usable' | undefined,
    exceptionCallback?: Function) {
    let errorMessages = [messageIdOperation as string];
    if (additionalMessageId !== undefined) {
      switch (additionalMessageId) {
        case 'deletion':
          errorMessages.push(`request.error.subscription-summary.deletion-success`);
          errorMessages.push(`request.error.subscription-summary.summary-not-up-to-date`);
          break;
        case 'summary-not-up-to-date':
          errorMessages.push(`request.error.subscription-summary.summary-not-up-to-date`);
          break;
        case 'summary-not-up-to-date':
            errorMessages.push(`request.error.subscription-summary.untracked-not-usable`);
            break;
        case 'check-for-subscriptions-after-purchase':
          errorMessages = [];
          break;
      }
    }
    if (errorMessages.length > 0) {
      this.errorHandler.showErrorMessage(errorMessages, error);
      if (exceptionCallback) {
        exceptionCallback();
      }
    }
  }
  
  async createLink(sourceId: string, target: string, description: string, imagefile: any,
    croppedImageTop: number, croppedImageLeft: number, croppedImageHeight: number, croppedImageWidth: number,
    collectCountry: boolean, collectBrowserInfo: boolean, collectDeviceType: boolean, collectDeviceDimensions: boolean, collectUserLanguage: boolean, collectOperatingSystemInfo: boolean): Promise<LinkResponse>  {
    let token = await this.auth.currentUser.getIdToken().catch(error => {
      this.errorHandler.showErrorMessage(['request.error.auth.general'], error);
    });
    if (!token)
      return Promise.reject();

    this.linkService.configuration.accessToken = token
    return this.linkService.apiLinksPost({
      sourceId: sourceId,
      target: target,
      description: description,
      collectCountry: collectCountry,
      collectBrowserInfo: collectBrowserInfo,
      collectDeviceType: collectDeviceType,
      collectDeviceDimensions: collectDeviceDimensions,
      collectUserLanguage: collectUserLanguage,
      collectOperatingSystemInfo: collectOperatingSystemInfo,
      image: imagefile,
      cropImageTop: croppedImageTop,
      cropImageLeft: croppedImageLeft,
      cropImageHeight: croppedImageHeight,
      cropImageWidth: croppedImageWidth
    }).toPromise()
    .catch(error => {
      return Promise.reject(error);
    });
  }

  async updateLink(sourceId: string, target: string, description: string, imagefile: any,
    croppedImageTop: number, croppedImageLeft: number, croppedImageHeight: number, croppedImageWidth: number,
    collectCountry: boolean, collectBrowserInfo: boolean, collectDeviceType: boolean, collectDeviceDimensions: boolean, collectUserLanguage: boolean, collectOperatingSystemInfo: boolean): Promise<void>  {
    let token = await this.auth.currentUser.getIdToken().catch(error => {
      this.errorHandler.showErrorMessage(['request.error.auth.general'], error);
    });
    if (!token)
      return Promise.reject();

    this.linkService.configuration.accessToken = token
    
    let updatedLink = await this.linkService.apiLinksSourcePut(sourceId, {
      sourceId: sourceId,
      target: target,
      description: description,
      collectCountry: collectCountry,
      collectBrowserInfo: collectBrowserInfo,
      collectDeviceType: collectDeviceType,
      collectDeviceDimensions: collectDeviceDimensions,
      collectUserLanguage: collectUserLanguage,
      collectOperatingSystemInfo: collectOperatingSystemInfo,
      image: imagefile,
      cropImageTop: croppedImageTop,
      cropImageLeft: croppedImageLeft,
      cropImageHeight: croppedImageHeight,
      cropImageWidth: croppedImageWidth
    }).toPromise()
    .catch(error => {
      return Promise.reject(error);
    });
    await this.store.dispatch(new StateActions.UpdateLink(updatedLink)).toPromise();
  }

  updateAvailableLinks(exceptionCallback?: Function): Promise<any> {
    return this.auth.currentUser.getIdToken().then((token) => {
      return this.updateAvailableLinksWithToken(token, exceptionCallback);
    }).catch(error => {
      if (exceptionCallback) {
        exceptionCallback();
        this.errorHandler.showErrorMessage(['request.error.auth.general', 'request.error.links.reload-button'], error);
      } else {
        this.errorHandler.showErrorMessage(['request.error.auth.general', 'request.error.links.awareness'], error);
      }
    });
  }

  async updateAvailableLinksWithToken(token: string, exceptionCallback?: Function): Promise<void> {
    this.linkService.configuration.accessToken = token;
    let result = await this.linkService.apiLinksGet("body", false).toPromise().catch(error => {
      if (exceptionCallback) {
        exceptionCallback();
        this.errorHandler.showErrorMessage(['request.error.links.general', 'request.error.links.reload-button'], error);
      } else {
        this.errorHandler.showErrorMessage(['request.error.links.general', 'request.error.links.awareness'], error);
      }
      return Promise.reject();
    });
    await this.store.dispatch(new StateActions.UpdateAvailableLinks(result)).toPromise();
  }

  updateSelectedLink(sourceId: string, checked: boolean): Promise<any> {
    if (checked) {
      let resultStat: Stat[]
      let resultStatSummary: StatSummary
      let loadLinkStatsFailed = false;
      let loadLinkStatsSummaryFailed = false;

      // check if data is still current ... develop mechanism to only party refresh data
      return this.auth.currentUser.getIdToken().then(token => {
        this.linkService.configuration.accessToken = token;
        let promiseLinkData = this.linkService.apiLinksIdStatsGet(sourceId).toPromise();
        promiseLinkData.then(result => resultStat = result).catch(error => {
          loadLinkStatsFailed = true;
        });
        let promiseSummary = this.linkService.apiLinksIdStatsAllSummaryGet(sourceId).toPromise()
        promiseSummary.then(result => resultStatSummary = result).catch(error => {
          loadLinkStatsSummaryFailed = true;
        });
        return Promise.all([promiseLinkData, promiseSummary]);
      }).then(_ => {
        return this.store.dispatch(new StateActions.UpdateSelectedLinkAndData({
          sourceId: sourceId,
          selected: checked,
          linkStats: resultStat,
          linkStatSummary: resultStatSummary
        }));
      }).catch(error => {
        if (loadLinkStatsFailed || loadLinkStatsSummaryFailed) {
          this.errorHandler.showErrorMessage([
            ... loadLinkStatsFailed ? ['request.error.stats.load-stats-for-link-failed'] : [],
            ... loadLinkStatsSummaryFailed ? ['request.error.stats.load-stats-summary-for-link-failed'] : [],
          ], error);
        } else {
          this.errorHandler.showErrorMessage(['request.error.auth.general'], error);
        }
        return Promise.reject();
      });
    }

    return this.store.dispatch(new StateActions.UpdateSelectedLink({
      sourceId: sourceId,
      selected: checked
    })).toPromise();
  }

  // REFACTORING: does this really belong here? It is no state which is modified
  async downloadLinkStats(sourceId: string): Promise<void> {
    const token = await this.auth.currentUser.getIdToken().catch(error => {
      this.errorHandler.showErrorMessage(['request.error.auth.general'], error);
    });
    if (!token)
      return Promise.reject();

    const fileName = `${formatDate(new Date(), 'yyMMdd-HHmm', 'en')}-stats-${sourceId}.csv`;
    await this.http.get(`${environment.apiServer}/api/links/${sourceId}/stats`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'text/csv'
        },
        observe: 'body',
        responseType: 'blob'
      })
      .toPromise()
      .then(blob => {
        if(blob) {
          saveAs(blob, fileName);
        }
      })
      .catch(error => {
        this.errorHandler.showErrorMessage(['request.error.stats.download-csv-failed'], error);
      });
  }

  async deleteLink(sourceId: string): Promise<boolean> {
    const request = {
      sourceId: sourceId
    } as DeleteLinkRequest;

    let token = await this.auth.currentUser.getIdToken().catch(error => {
      // TODO OO specify that the error was about deletion
      this.errorHandler.showErrorMessage(['request.error.auth.general'], error);
    });
    if (!token)
      return Promise.reject();

    let deletionSuccess = true;
    await this.http.delete(
      `${environment.apiServer}/api/links`,
      { headers: { "Authorization": `Bearer ${token}` }, body: request })
        .toPromise()
        .catch(error => {
          deletionSuccess = false;
          this.errorHandler.showErrorMessage(['request.error.deletion.failed'], error);
        });

    if (deletionSuccess) {   
      await this.updateSelectedLink(sourceId, false);          
      await this.updateAvailableLinksWithToken(token);
      this.readSubscriptionSummary('request.error.deletion.general', 'deletion', undefined, token);
    } else {
      return Promise.reject();
    }
  }
}
