import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InformationOnlyDialogComponent } from '../dialoges/information-only-dialog/information-only-dialog.component';

@Component({
    selector: 'app-footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent {
    today: Date = new Date();
    constructor(private dialog: MatDialog) { }

    openDialog(titleTranslationKey: string, contentTranslationKey: string) {
        this.dialog.open(InformationOnlyDialogComponent, {
            minWidth: '92vw',
            maxWidth: '92vw',
            minHeight: '92vh',
            maxHeight: '92vh',
            disableClose: true,
            data: {
                titleTranslationKey,
                contentTranslationKey
            }
        });
    }
}
