import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LoadingDialogComponent } from '../shared/components/dialoges/loading-dialog/loading-dialog.component';
import { take, takeUntil } from 'rxjs/operators';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

declare const $: any;

@Injectable({
  providedIn: 'root'
})
export class UiStateService {

  public executionFinished = new BehaviorSubject<boolean>(true);
  public cssHtmlDisabled = new BehaviorSubject<string>('');

  constructor(private dialog: MatDialog) {
    this.executionFinished.subscribe(value => {
      this.cssHtmlDisabled.next(value ? '': 'disabled');
    });
  }

  showLoadingDialog() {
    if (this.executionFinished.value) {
      this.executionFinished.next(false);
      const dialogRef = this.openLoadingDialog();
  
      dialogRef.afterOpened().pipe(take(1)).subscribe(() => {
        const closeOnFinish = new Subject();
        this.executionFinished.pipe(takeUntil(closeOnFinish)).subscribe(value => {
          if (value) {
            closeOnFinish.next();
            closeOnFinish.complete();
            dialogRef.close();
          }
        });
      });
      return dialogRef;
    }
    return undefined;
  }

  hideLoadingDialog() {
    this.executionFinished.next(true);
  }

  protected openLoadingDialog() {
    const dialogRef =  this.dialog.open(LoadingDialogComponent, {
      panelClass: 'dialog-transparent',
      width: '100vw',
      minWidth: '320px',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      scrollStrategy: new NoopScrollStrategy(),
      disableClose: true
    });

    dialogRef.afterOpened().pipe(take(1)).subscribe(() => {
      const dialogContainer = $('.dialog-transparent').parent();
      dialogContainer.css('z-index', 3010);
      dialogContainer.prev().css('z-index', 3000);
    });

    return dialogRef;
  }
}
