import { Injectable } from '@angular/core';
import { ToastService } from './toast.service';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private toastService: ToastService,
              private translocoService: TranslocoService) {
  }

  showErrorMessage(messageId: string[], error: any) {
    const activeLang = this.translocoService.getActiveLang();
    this.translocoService.load(activeLang).pipe(take(1)).subscribe(_ => {
      let message = '';
      messageId.forEach(messageId => {
        if (message.length == 0) {
          message = this.translocoService.translate(messageId);
        } else {
          message = message + ' ' + this.translocoService.translate(messageId);
        }
      });
      this.toastService.showToast('error', message, 5000, true);
    });
  }
}
