

        <div class="logo">
          <a class="simple-text logo-mini" [href]="consoleUrl">
            <div class="logo-img">
                <img src="../../assets/img/qrify.me-logo-icon.svg"/>
            </div>
            
          </a>
            <a class="simple-text logo-normal">
              <div class="logo-img">
                <img src="../../assets/img/qrify.me-logo-writing.svg"/>&nbsp;
              </div>
            </a>
        </div>

        <div class="sidebar-wrapper">
            <div class="show-mobile-menu">
              <ul class="nav navbar-nav nav-mobile-menu">
                <li class="nav-item dropdown">
                  <a href="javascript:void(0)" class="nav-link" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">person</i>
                    <p>
                      <span class="d-lg-none d-md-block">{{ 'navigation.user' | transloco }}</span>
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">{{ 'navigation.user-logout' | transloco }}</a>
                  </div>
                </li>
              </ul>
            </div>
            <ul class="nav">
                <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
                    <!--If is a single link-->
                    <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" [ngClass]="this.uistate.cssHtmlDisabled | async" class="nav-link">
                        <i class="material-icons">{{menuitem.icontype}}</i>
                        <p>{{ menuitem.title | transloco }}</p>
                    </a>
                    <!--If it have a submenu-->
                    <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'" (click)="updatePS()" [ngClass]="this.uistate.cssHtmlDisabled | async" class="nav-link">
                        <i class="material-icons">{{menuitem.icontype}}</i>
                        <p>{{ menuitem.title | transloco }}<b class="caret"></b></p>
                    </a>

                    <!--Display the submenu items-->
                    <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
                        <ul class="nav">
                            <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
                                <a [routerLink]="[menuitem.path, childitem.path]" [ngClass]="this.uistate.cssHtmlDisabled | async" class="nav-link">
                                    <span class="sidebar-mini">{{childitem.ab}}</span>
                                    <span class="sidebar-normal">{{ childitem.title | transloco }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
