import { Injectable } from '@angular/core';
import { Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, UserCredential, sendEmailVerification, reload, onAuthStateChanged, User, sendPasswordResetEmail, updateEmail, updatePassword } from '@angular/fire/auth'
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = new Subject<User>()
  loggedInUser = new BehaviorSubject<string | undefined>(undefined)
  emailVerified = new BehaviorSubject<boolean | undefined>(undefined)
  emailSend = false

  constructor(public auth: Auth) {
    onAuthStateChanged(auth, user => {
      if (user) {
        this.loggedInUser.next(user.email || '')
        this.handleEmailVerification(user)
        this.user.next(user)
      } else {
        this.loggedInUser.next(undefined)
        this.emailVerified.next(undefined)
      }
    });

    this.auth.onIdTokenChanged(user => {
      if (user) {
        this.user.next(user)
      }
      if (user?.emailVerified) {
        this.handleEmailVerification(user)
      }
    });
  }

  handleEmailVerification(user: User) {
    if (user.emailVerified) {
      this.emailVerified.next(true)
    } else {
      this.emailVerified.next(false)
      if (!this.emailSend) {
        sendEmailVerification(user)
        this.emailSend = true
      }
    }
  }

  observeForEmailVerified() {
    const interval = setInterval(() => {
      this.emailVerified.pipe(take(1)).subscribe(verified => {
        if (!verified) {
          if (this.auth?.currentUser && this.auth?.currentUser?.emailVerified) {
            this.emailVerified.next(true)
            clearInterval(interval);
          } else if (this.auth?.currentUser) {
            reload(this.auth.currentUser!)
          }
        }
      })
    }, 8000)
  }

  resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email)
  }

  register(email: string, password: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.auth, email, password)
  }

  signinWithEmailAndPassword(username: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, username, password);
  }

  signout(): Promise<void> {
    this.emailVerified.next(false)
    return signOut(this.auth);
  }

  fetchData(): [email: string, uid: string] {
    return [this.auth.currentUser?.email || '', this.auth.currentUser?.uid || '']
  }

  updateUsernamePassword(username: string, password: string): Promise<void[]> {
    var updatePromises: Promise<void>[] = []
    var user = this.auth.currentUser

    if (user.email != username) {
      updatePromises.push(updateEmail(user, username))
    }

    if (password) {
      updatePromises.push(updatePassword(user, password))
    }

    return Promise.all(updatePromises)
  }
}
